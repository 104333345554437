/* bio.css */
@media (min-width: 6.25em) {
    html {
        font-size: 17px;
    }
}

/* ... (other media queries for font-size) ... */
.section {
    position: relative;
    display: block;
    flex-flow: column nowrap;
    align-items: stretch;
    min-height: 60vh;
    width: 100%;
    align-content: right;
    padding: 20vh 10vw 10vh 5vw;
}

.section__inner {
    height: 100%;
    max-width: 800px;
    margin: auto auto auto 0;
    text-align: right;
}

#loudHeader {
    /* font-family: "nimbus-sans", sans-serif; */
    font-family: 'Oswald', sans-serif;
    line-height: 1;
    letter-spacing: -1px;
    font-weight: bold;
    margin: 0 0 1em;
    font-size: 3.5rem;
    /* text-transform: uppercase; */
}

.section--intro {
    opacity: 0;
    color: var(--text-color);
}

.section--intro .section__inner {
    height: 100%;
    max-width: 800px;
    margin: auto auto auto 0;
}

.section--intro .pre {
    display: inline-block;
    font-size: 12px;
    margin: 0;
    opacity: 0.8;
    letter-spacing: 1.4px;
    word-spacing: 0.2rem;
    margin-bottom: 1em;
}

.section--intro .pre span {
    font-size: 0.9em;
    margin-right: 0.4em;
    opacity: 0.5;
}

.section--intro .pre:hover {
    opacity: 1;
}

.section--intro h1:not(#loudHeader) {
    font-family: var(--font-sans);
    font-weight: bold;
    margin: 0 0 1em;
    line-height: 1;
    letter-spacing: -1px;
}

@media (min-width: 30rem) {
    .section--intro h1:not(#loudHeader) {
        letter-spacing: -2px;
    }
}

.section--intro p.lead {
    display: block;
    font-size: 0.875rem;
    margin: 0;
}

@media (min-width: 30rem) {
    .section--intro p.lead {
        font-size: 1rem;
    }
}

.section--intro p.lead a {
    font-weight: bold;
}

.section--intro p.lead a:hover {
    border-bottom: 0.1875rem solid;
}

.lead {
    font-family: var(--font-serif-secondary);
    font-size: 0.75rem;
    line-height: 1.4;
    display: block;
    font-size: 0.875rem;
    margin: 0;
}

.right-panel-bio {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-panel-bio {
    width: 50%
}

.primary-text {
    color: var(--primary);
}

.container-bio {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.primary-text {
    color: var(--primary);
    transition: all 0.3s ease-in-out;
}
