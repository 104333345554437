/* #fullPage {
    background: linear-gradient(to bottom, var(--light-tan), var(--warm-medium-brown));
} */

.container {
    display: flex;
    height: 100vh;
    width: 100vw;
}

.top-panel {
    font-family: var(--font-sans);
    color: var(--text-color);
    width: 100%;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1px;
    font-size: 6vh;
    background-color: transparent;
    position: fixed;
    text-align: center;
    padding: 35% 10% 0% 10%
}

.left-panel-text {
    opacity: 0;
}

.bottom-panel {
    width: 100%;
    height: 100%;
}
