@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Merriweather:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&family=Merriweather:wght@400;700&display=swap');


/* Light theme */
:root {
  --background-color: #F7F2E6;
  --background-color-secondary: #e6e7e9;
  --background-color-tertiary: #E5E0D4;
  --off-white: #e6e7e9;
  --primary: #f86839;
  --black: #555;
  --text-color: #15181d;
  --text-color-secondary: #282c34;
  --text-color-tertiary: #1e2328;
  --font-serif: "ff-meta-serif-web-pro", serif;
  --font-sans: 'Roboto', sans-serif;
  --font-serif-secondary: 'Merriweather', serif;
}

/* Dark theme */
[data-theme="dark"] {
  --background-color: #15181d;
  --background-color-secondary: #282c34;
  --background-color-tertiary: #1e2328;
  --text-color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  transition: background-color 0.3s, color 0.3s;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}