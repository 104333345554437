.main-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    font-family: var(--font-sans);
    overflow: hidden; /* ensure no scrolling occurs */
    overflow-y: auto;
    align-items: stretch; /* Changed from center to stretch to use full container height */
}

.project-details {
    background: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    flex: 0 0 50%;
}

.project-details.active {
    opacity: 1;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Start with four columns by default */
    gap: 20px;
    width: 100%;
    max-height: 100%;
    padding-right: 5%;
    overflow: auto;
    border-radius: 12px;
}

@media (max-width: 1200px) {
    .grid-container {
        grid-template-columns: 1fr; /* Change to one column when screen is small */
    }
}

.project-list {
    flex: 0 0 45%;
    background: var(--background-color-tertiary);
    display: flex;
    align-items: start;
    justify-content: center;
    padding: 30px;
    margin: 10vh 0;
    height: auto;
    border-radius: 2rem;
    margin-left: 10px;
    margin-right: 10px;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 0 2px var(--text-color), 0 0 2px var(--text-color); /* Add box shadow with --text-color */
}

.project-item {
    width: auto;
    height: 30vh; 
    background-size: cover;
    background-position: center;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 12px;
    transition: filter 0.3s ease-in-out, opacity 0.3s ease-in-out;
    position: relative;
    overflow: visible;  /* Ensures the overlay does not spill outside the item */
}

.project-name-overlay {
    font-family: 'Osward', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--off-white);
    font-size: 1rem; /* Larger font size for visibility */
    z-index: 10; /* Ensure the text appears above the background image */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease;
    text-align: center; /* Center the text */
    font-weight: bold;
}

.project-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* No background color initially */
    transition: background-color 0.3s ease;
    border-radius: 8px;
    z-index: 1; /* Ensures overlay is above the background but below the text */
}

.project-item.selected .project-item-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Darken the overlay to indicate selection */
}

.project-item.selected .project-name-overlay {
    opacity: 1; /* Show the project name on selected items */
}

.project-item:hover .project-item-overlay,
.project-item:hover .project-name-overlay {
    border-radius: 8px;
    padding: 5%;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 1; /* Make the overlay and text visible */
    font-size: 1rem;

}

.project-item:hover {
    opacity: 1; /* Make the overlay and text visible */
    background-color: rgba(0, 0, 0, 0.5); /* Darken the overlay to indicate selection */
    filter: none !important;
    border-radius: 12px;
}


.project-item {
    transition: filter 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.project-item:hover {
    background-color: var(--background-color-tertiary);
    opacity: 0.8;
}

.project-item.selected {
    filter: none; /* Darken the item to indicate selection */
    transform: scale(0.95); /* Optional: slightly scale up selected item */
    overflow: visible !important;
    border: 4px solid var(--primary);
}

.project-details {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    opacity: 0;
    transform: translateY(20px);
    display: flex;
    flex-direction: column; /* Change flex direction to column */
    align-items: left;
    justify-content: center;
    padding: 5rem;
}

.project-details.active {
    opacity: 1;
    transform: translateY(0);
}

.project-title, .project-description, .project-link {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.project-details.active .project-title {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s; /* Delay the start slightly */
}

.project-details.active .project-description {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.4s; /* Further delay for description */
}

.project-details.active .project-link {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.5s; /* Further delay for link */
}

.project-title h1 {
    font-size: clamp(3vw, calc(3vw + 1.5 * (100vw - 320px) / 680), 5vw); /* Large, bold font size with responsive scaling */
    color: transparent;
    -webkit-text-stroke: 1px var(--text-color); /* Adjust stroke width as needed */
    font-family: 'Oswald', sans-serif;
    font-weight: bold; /* Bold font weight */
    margin-bottom: 1.5vh; /* Space between title and description */
    overflow-wrap: break-word; /* Wrap long words to prevent overflow */
}

.project-description p {
    font-size: clamp(1vw, calc(1vw + 0.5 * (100vw - 320px) / 680), 2vw); /* Smaller font size than the title with responsive scaling */
    /* font-family : var(--font-serif-secondary); */
    font-family: 'Oswald', sans-serif;
    margin-bottom: 2vh; /* Space between description and link */
    overflow-wrap: break-word; /* Wrap long words to prevent overflow */
}

.project-link a {
    display: inline-block;
    background-color: transparent; /* Vibrant orange color, similar to your --primary variable */
    color: var(--text-color);
    text-decoration: none; /* Remove underline */
    padding: 10px 20px; /* Ample padding for a button-like appearance */
    /* border-radius: 20px;  */
    transition: background-color 0.3s, transform 0.2s; /* Smooth transition for hover effects */
    font-weight: bold; /* Bold text */
    font-size: 16px; /* Appropriate font size */
    text-align: center; /* Center text within the button */
    border: 2px solid var(--text-color) ;
}

.project-link a:hover, .project-link a:focus {
    background-color: var(--primary); /* Lighter shade of orange for hover effect */
    transform: translateY(-2px); /* Slight raise to give a button press effect */
    cursor: pointer; /* Cursor pointer to indicate it's clickable */
}

.project-link a:active {
    background-color: var(--primary); /* Even darker shade of orange when the button is actively pressed */
    transform: translateY(1px); /* Lower the button to simulate pressing */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}


@media (max-width: 640px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}

.main-container {
    overflow-y: hidden; 
}

.project-list {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    height: auto;
    overflow: visible;
}

/* Custom scrollbar for project list using root variables */
.project-list::-webkit-scrollbar {
    width: 4px; /* Thinner scrollbar */
    background: transparent; /* Makes the scrollbar invisible */
}

.project-list::-webkit-scrollbar-track {
    background: transparent; /* Makes the track invisible */
}

.project-list::-webkit-scrollbar-thumb {
    background-color: var(--background-color-secondary); /* Uses secondary color for the thumb */
    border-radius: 12px; /* Smaller border radius for a subtler look */
    border: 2px solid transparent; /* Invisible border to maintain the shape without visual clutter */
}

.project-list::-webkit-scrollbar-thumb:hover {
    background-color: var(--black); /* Darker color on hover for better interactivity visibility */
}

/* Firefox scrollbar styles */
.project-list {
    scrollbar-width: thin; /* 'auto' or 'thin' */
    scrollbar-color: var(--background-color-secondary) transparent; /* Thumb color and transparent track */
}

/* Safari scrollbar styles */
@supports (-webkit-appearance: none) {
    .project-list {
        scrollbar-width: thin; /* 'auto' or 'thin' */
        scrollbar-color: var(--background-color-secondary) transparent; /* Thumb color and transparent track */
        -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on Safari */
    }
}


.no-scroll {
    overflow: hidden !important; /* Ensuring it takes precedence */
}

.project-open-source {
    margin-bottom: 0.7vh; /* Space between the description and the link */
    font-size: 0.8rem; /* Adjust font size as needed */
    font-family: var(--font-serif); /* Use serif font for a more traditional look */
    word-spacing: 0.2rem;
    text-transform: uppercase;
}