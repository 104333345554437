/* Body Styles */
.panel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #fff;
    font-size: 10vmin;
}

/* Panel Colors */
.panel-1 {
    background: #012345;
}

.panel-2 {
    background: #123456;
}

.panel-3 {
    background: #234567;
}

.panel-4 {
    background: #345678;
}

.panel-5 {
    background: #456789;
}

/* Top Arrow */
.top {
    font-size: 80%;
    color: hsla(0, 0%, 100%, 0.25);
    position: relative;
    top: -0.2em;
    cursor: pointer;
}

