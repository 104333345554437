nav.topnav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    /* Using Flexbox */
    justify-content: space-between;
    /* Aligns children to both ends */
    align-items: center;
    /* Vertically centers the items in the navbar */
    background-color: transparent;
    font: var(--font-sans);
    /* Corrected typo from '--var(font-sans)' to 'var(--font-sans)' */
    font-weight: bold;
    font-size: 1rem;
    z-index: 1000;
    /* Ensure the nav appears on top */
    padding: 0 1.5vw;
    /* Add horizontal padding */
}

nav.topnav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    text-transform: lowercase;
    /* Align items in a row */
    justify-content: center;
    /* Center the navigation items */
    flex-grow: 1;
    /* Allows the nav items to take up as much space as possible */
}

nav.topnav li {
    margin: 1.25em;
    /* Adjusted for better spacing */
}

nav.topnav a {
    padding: 10px;
    text-decoration: none;
    color: var(--text-color);
    transition: all 0.12s ease;
    cursor: pointer;
}

nav.topnav a.active {
    border-bottom: 2px solid var(--text-color);
    border-top: 2px solid var(--text-color);
}

nav.topnav a:hover {
    color: var(--primary);
}

.theme-toggle-container {
    display: flex;
    align-items: center;
}