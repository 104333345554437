.border {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    background: transparent;
    transition: border 1s;
    position: relative;
    width: 100%;
}

.card {
    transition: background 0.8s;
    overflow: hidden;
    background: #000;
    display: flex;
    justify-content: center;
    position: relative;
    background: url("../images/mapLoc.png") center center no-repeat;
    background-size: cover;
    height: 100%;
    border-radius: 5% 0 0 5%;
    width: 100%; /* Added to fill the space */
}

.card:hover {
    background: url("../images/mapLoc.png") left center no-repeat;
    background-size: cover;
}

.card:hover h2 {
    opacity: 1;
}

.card:hover .fa {
    opacity: 1;
}

.border h2 {
    font-size: 1em;
    text-align: left;
    font-weight: bold;
    color: #fff;
    margin: 5%;
    opacity: 0;
    transition: opacity 1s;
}

html {
    box-sizing: border-box;
}

html *,
html *:before,
html *:after,
html *::before,
html *::after {
    box-sizing: inherit;
}

body {
    padding: 0;
    margin: 0;
    font-family: sans-serif;
}


dl,
dd,
menu,
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

ol {
    list-style-type: decimal;
}

img {
    max-width: 100%;
    height: auto;
    border-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}


.parallax-container {
    position: relative;
    width: 100%;
    height: 100vh; /* Adjust based on your design needs */
    /* background: linear-gradient(#2b1055, #7597de); */
    overflow: hidden;
}

.parallax-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    will-change: transform; /* Improves performance of animations */
}

#background-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Ensure proper stacking */
}

.cardProfile {
    position: absolute; /* Bring content above background */
    width: 50rem;
    height: 30rem;
    display: flex;
    z-index: 1; /* Higher than the parallax background */
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 5%;
}

.half {
    width: 50%;
}

.half-right {
    background-color: var(--text-color);
    padding: 1.5rem 1rem 4rem 1rem;
    position: relative;
    text-align: center;
    border-radius: 0 5% 5% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.half-right h3 {
    color: var(--background-color-secondary);
    margin: 0.5rem 0;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: var(--font-sans);
}


.half-right button:hover {
    transform: translateY(-3px);
}

.half-right p {
    color: var(--background-color-secondary);
    font-size: 0.9rem;
    margin: 0.2rem 3.7rem;
    font-weight: 500;
    font-family: var(--font-serif);
}

.half-left {
    border-radius: 5% 0 0 5%;
}
