.contact-page {
    display: flex;
    height: 100vh; /* Full viewport height */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
    color: var(--text-color);
    font-family: var(--font-sans);
}

.contact-left, .contact-right {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.contact-left {
    flex: 40%;  /* 40% of the container width */
    text-align: left;
    margin-left: 10vw;
}

.contact-right {
    flex: 60%;  /* 60% of the container width */
}

.contact-form {
    font-size: 2.5em;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    text-transform: lowercase;

}

.contact-left h1 {
    font-weight: bold;
    font-size: 4em;
    margin-bottom: 0px;
    letter-spacing: 3.5px;
    text-transform: lowercase;
    transition: 0.3s;
}

.contact-left p {
    font-family: var(--font-serif-secondary);
    font-size: 0.8rem;
    margin-bottom: 20px;
    text-transform: lowercase;
}

.contact-form p {
    font-weight: bold;
    width: 80%; /* Adjust as needed */
    margin-bottom: 20px;
    letter-spacing: 3.5px
}

.contact-form input {
    background: transparent;
    border: none;
    border-bottom: 2px solid var(--text-color);
    color: var(--text-color);
    margin: 0 5px;
    font-weight: bold;
    font-size: 0.5em;
    outline: none;
    width: 18vw;
    max-width: 45vw;
    display: inline-block;
    transition: 0.3s ease; /* Smooth transition for background color */
    padding: 5px; /* Add padding for better visual */
    text-transform: lowercase;
    overflow-x: auto;  /* Allow horizontal scrolling */
    white-space: nowrap; /* Keep text in a single line */
}

.contact-form input:focus {
    overflow-y: auto; /* Allow scrolling when focused */
    color: var(--primary); /* Optional: Change text color when focused */
    scale: 1.01;
    border-bottom: 2px;
    font-size: 0.8em;
    border-bottom: 2px solid var(--primary); /* Change border color when focused */
    background: var(--background-color-tertiary); /* Change background color when focused */
}

.contact-form input, .contact-form textarea {
    min-width: 100px; /* Consistent minimum width */
    box-sizing: border-box; /* Include padding and border in width */
    display: inline-block; /* Align inline */
    vertical-align: middle; /* Align vertically */
}


/* Hide placeholder on focus */
.contact-form input:focus::placeholder {
    color: transparent; /* Makes the placeholder text transparent */
}

.contact-form button {
    padding: 10px 30px 10px 0;
    background-color: transparent;
    color: var(--primary);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: var(--font-sans);
    margin-top: 20px;
    border-radius: 5px;
    transition: 0.3s;
    font-size: 0.4em;
    font-weight: bold;
}

.contact-form button:hover {
    /* background-color: darken(var(--primary), 10%); */
    transform: translateX(3px);
}

.fa {
    font-size: 1.5rem;
    color: var(--text-color);
    margin: 5% 5% 0 0;
    transition: opacity 1s;
    transition: 0.3s;
}

.fa:hover {
    fill: var(--primary);
    color: var(--primary);
    font-size: 1.8em;
    height: 1.8em;
    transform: translateY(-2px);
}

.svg-leetcode {
    transition: transform 0.3s ease-in-out;
}

.svg-leetcode:hover {
    transform: scale(1.2);
}

.icons {
    width: 100%;
    fill: #fff;
    color: #fff;
    /* height: 50px; */
    /* top: 80%; */
    width: -webkit-fill-available;
    padding: 5% 5% 5% 0;
}

.icons p {
    color: var(--text-color);
}