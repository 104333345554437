nav.sidenav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh; /* Full height of the viewport */
    width: auto; /* Adjust width as needed */
    flex-direction: column; /* Stack items vertically */
    background-color: transparent; /* Semi-transparent background */
    color: transparent;
    z-index: 1000; /* Keep on top of other content */
    padding: 1em 0; /* Vertical padding */
    overflow-y: auto; /* Allows scrolling for many items */
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

nav.sidenav ul {
    list-style-type: none;
    width: auto; /* Full width of the navbar */
    padding: 0;
    margin: 0;
    background-color: transparent;
}

nav.sidenav li {
    width: 100%; /* Full width of the list item */
    margin-bottom: 1em; /* Space between items */
    background-color: transparent;
}

nav.sidenav button {
    cursor: pointer;
    display: block; /* Make links fill the container */
    width: auto;
    border: none;
    padding: 10px 20px; /* Ample padding for clickable area */
    border-radius: 5%;
    text-decoration: none;
    background-color: transparent;
    color: var(--text-color);
    text-align: center; /* Center text */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

nav.sidenav button.active {
    background-color: var(--primary); /* Highlight for active link */
    color: #fff;
}

nav.sidenav button:hover {
    background-color: var(--background-color-tertiary); /* Different background on hover */
    color: #fff;
}

.menu-dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: var(--background-color-tertiary);
    margin-left: 5px;
    transition: background-color 0.3s;
    display: inline-block;
}

.menu-dot.active {
    background-color: var(--primary);
}
