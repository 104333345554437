/* #fullPage {
    background: linear-gradient(to bottom, var(--light-tan), var(--warm-medium-brown));
} */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.model-viewer-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    /* Ensures model is behind the front text and in front of the back text */
}

.text {
    position: absolute;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1px;
    display: flex;
    width: auto;
    /* auto width based on text content */
    height: auto;
    /* auto height based on text content */
    pointer-events: none;
    /* Allows clicks to pass through to the model viewer */
    font-family: 'Oswald', sans-serif;
    font-size: 4vw;
    /* Adjust font size as needed */
    color: transparent;
    -webkit-text-stroke: 1px var(--text-color);
    /* Adjust stroke width as needed */
    text-transform: lowercase;
}

.text.behind {
    z-index: 0;
    /* Behind the model */
    justify-content: flex-start;
    align-items: flex-start;
    top: 35vh;
    /* Adjust these based on actual appearance */
    left: 16vw;
    /* Percentage of the viewport width */
    transform: translate(0, 0);
    /* Adjust or remove translation if necessary */
}

.text.infront {
    z-index: 2;
    /* In front of the model */
    justify-content: flex-end;
    align-items: flex-end;
    bottom: 30vh;
    /* Adjust these based on actual appearance */
    right: 12vw;
    /* Percentage of the viewport width */
    transform: translate(0, 0);
    /* Adjust or remove translation if necessary */
    -webkit-text-stroke: none;
    /* Remove stroke */
    color: var(--text-color);
}


@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-name: fadeIn;
}

.model-viewer-wrapper {
    position: relative;
    height: 100vh;
    /* Adjust according to your layout needs */
}

.overlay-text {
    position: absolute;
    top: 20%;
    /* Adjust this value to move it up or down */
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: var(--text-color);
    /* Ensure the text color contrasts with the model */
    z-index: 10;
    /* Higher than the model viewer */
    font-size: 1;
    /* Adjust font size as needed */
    pointer-events: none;
    /* Allows clicks to pass through to the model below */
    font-family: var(--font-serif-secondary);
}

@-webkit-keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

@-moz-keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

@keyframes ani-mouse {
    0% {
        opacity: 1;
        top: 29%;
    }

    15% {
        opacity: 1;
        top: 50%;
    }

    50% {
        opacity: 0;
        top: 50%;
    }

    100% {
        opacity: 0;
        top: 29%;
    }
}

.scroll-btn {
    display: block;
    /* position: absolute; */
    left: 0;
    right: 0;
    text-align: center;
}

.scroll-btn>* {
    display: inline-block;
    line-height: 18px;
    font-size: 13px;
    font-weight: normal;
    color: #7f8c8d;
    color: var(--text-color);
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, sans-serif;
    letter-spacing: 2px;
}

.scroll-btn>*:hover,
.scroll-btn>*:focus,
.scroll-btn>*.active {
    color:var(--text-color);
}

.scroll-btn>*:hover,
.scroll-btn>*:focus,
.scroll-btn>*:active,
.scroll-btn>*.active {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.scroll-btn .mouse {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 35px;
    height: 55px;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 3px solid var(--text-color);
    border-radius: 23px;
}

.scroll-btn .mouse>* {
    position: absolute;
    display: block;
    top: 29%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin: -4px 0 0 -4px;
    background: var(--text-color);
    border-radius: 50%;
    -webkit-animation: ani-mouse 2s linear infinite;
    -moz-animation: ani-mouse 2s linear infinite;
    animation: ani-mouse 2s linear infinite;
}