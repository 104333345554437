.cursor, .cursor2, .cursor3 {
    position: fixed;
    left: 0;
    top: 0;
    height: 8px;
    border-radius: 50%;
    /* background: var(--text-color); */
    pointer-events: none;
    z-index: 1000;
    transform: translate(-50%, -50%);
    will-change: left, top; 
}


.cursor {
    background-color: var(--text-color);
    height: 0;
    width: 0;
    z-index: 99999;
}

.cursor2, .cursor3 {
    height: 36px;
    width: 36px;
    z-index: 99998;
    transition: all 0.3s ease-out;
}

.cursor2 {
    border: 2px solid var(--text-color);
    box-shadow: 0 0 22px rgba(255, 255, 255, 0.6);
}

.cursor3 {
    /* If .cursor3 has specific styles, they should be added here */
}

.cursor2.hover,
.cursor3.hover {
    transform: scale(2) translate(-25%, -25%);
    border: none;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 12px rgba(255, 255, 255, 0.2);
}

.click-effect {
    font-family: 'Oswald', sans-serif;
    position: fixed;
    color: var(--text-color); /* Red text, but customize as you like */
    pointer-events: none; /* Allows clicks to pass through */
    animation: fadeMoveUp 3s ease forwards; /* Animation to move up and fade out */
    transform: translate(-50%, -20px); /* Centering the text on the cursor */
    white-space: nowrap; /* Prevent text from wrapping */
    z-index: 99999; /* Keep on top of other content */
}

@keyframes fadeMoveUp {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-50px); /* Move text 50px up */
    }
}


@media screen and (max-width: 1200px) {
    .cursor, .cursor2, .cursor3 {
        display: none;
    }
}
